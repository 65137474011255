import axios from 'axios';
import { fileNameAndExt } from './Functions';
import { toast } from 'react-toastify';
import React from 'react'
import moment from 'moment'
const CancelToken = axios.CancelToken;

export const __isDebug = '_self' in React.createElement('div')

let baseAdminDomain = window.location.host.toString();
const scheme = __isDebug ? 'http://' : 'https://';
const wsScheme = __isDebug ? 'ws://' : 'wss://';

let baseDomain;
switch (baseAdminDomain) {
  case "admin-dev.hubswitch.com":
    baseDomain = "api-dev.hubswitch.com";
    break;
  case "admin-beta.hubswitch.com":
    baseDomain = "api-beta.hubswitch.com";
    break;
  case "admin-hr.hubswitch.com":
  case "admin-booking.hubswitch.com":
    baseDomain = "api-test.hubswitch.com";
    break;
  case "admin.hubswitch.com":
    baseDomain = "api.hubswitch.com";
    break;
  case "127.0.0.1:3000":
    baseDomain = "192.168.1.51";
    break;
  case "admin-yok.hubswitch.com":
    baseDomain = "api-beta.hubswitch.com";
    break;
  case "admin-touch.hubswitch.com":
    baseDomain = "api-beta.hubswitch.com";
    break;
  case "admin-ton.hubswitch.com":
    baseDomain = "api-beta.hubswitch.com";
    break;
  case "admin-account.hubswitch.com":
    baseDomain = "api-test.hubswitch.com";
    break;
  default:
    baseDomain = window.location.hostname + ":3333";
    // baseAdminDomain = "192.168.1.34:3000";
    break;
}
console.log('baseDomain', baseDomain)
const baseUrl = scheme + baseDomain + '/admin'
// const baseUrl = 'https://api-beta.hubswitch.com/admin'
const baseApiUrl = baseUrl;
const baseAdminUrl = scheme + baseAdminDomain

const requester = axios.create({
  baseURL: baseApiUrl
});

export const showCommonError = function (error) {
  if (error.response && error.response.data) {
    var msg;
    switch (error.response.status) {
      case 401:
        msg = 'รหัสผ่าน/การเข้าสู่ระบบผิดพลาด';
        break;
      case 403:
        msg = 'ขออภัย คุณไม่ได้รับสิทธิการเข้าถึงข้อมูลนี้';
        break;
      case 404:
        msg = 'ไม่พบข้อมูล';
        break;
      //case 500:
      //msg = 'เกิดข้อผิดพลาดเซิฟเวอร์ กรุณาติดต่อเจ้าหน้าที่';
      //break;
      default:
        if (error.response.data.error) {
          msg = error.response.data.error.message;
        } else {
          msg = error.response.data.errors?.[0].message || error.response.data.message;
        }
        break;
    }
    let msgHtml = () => <div
      dangerouslySetInnerHTML={{ __html: msg.replaceAll('\r\n', '<br/>') }}
    />
    if (error.response.status != 422)
      toast.error(msgHtml)
    else
      toast.warn(msgHtml)
  }
};

const objectToFormData = function (obj, form, namespace) {

  var fd = form || new FormData();
  var formKey;

  if (namespace && Array.isArray(obj) && obj.length == 0) {
    fd.append(namespace, []);
    return
  }

  for (var property in obj) {

    if (obj.hasOwnProperty(property)) {

      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      if (obj[property] instanceof Date) {
        fd.append(formKey, moment(obj[property]).toISOString());
      } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {

        objectToFormData(obj[property], fd, formKey);
      } else {

        // if it's a string or a File object
        if (obj[property] !== undefined) {
          fd.append(formKey, obj[property]);
        }
      }

    }
  }

  return fd;

};

const form = (params, files = null) => {
  var formData = objectToFormData(params)

  if (files) {
    for (var i in files) {
      if (Array.isArray(files[i])) {
        for (var j in files[i]) {
          if (files[i][j] instanceof File || files[i][j] instanceof Blob) {
            formData.append(i + '[]', files[i][j]);
          }
          //  else {
          //   const uri = files[i][j].uri?files[i][j].uri:files[i][j].path;
          //   const image = {
          //       uri: uri,
          //       type: files[i][j].type?files[i][j].type:files[i][j].mime,
          //       name: files[i][j].name ? files[i][j].name : fileNameAndExt(uri)
          //   };
          //
          //   formData.append(i + '[]', image);
          // }
        }
      } else {
        if (files[i] instanceof File || files[i] instanceof Blob) {
          formData.append(i, files[i]);
        }
        // else {
        //   const uri = files[i].uri?files[i].uri:files[i].path;
        //   const image = {
        //       uri: uri,
        //       type: files[i].type?files[i].type:files[i].mime,
        //       name: files[i].name ? files[i].name : fileNameAndExt(uri)
        //   };
        //
        //   formData.append(i, image);
        // }
      }
    }
  }

  formData = modifier(formData, params);

  return formData;
}

const getToken = () => {
  return localStorage.getItem('api_token');
}

const getHeader = (token = null) => {
  token = token ? token : getToken();

  if (!token) {
    return {};
  }

  return { 'Authorization': 'Bearer ' + token };
}

export const getLink = () => {
  return {
    url: scheme + baseDomain,
    baseUrl: baseUrl,
    headers: getHeader()
  }
}

const api = {
  requestPromise: (url, params = {}, method = 'GET', options) => {

    options = { ...{ showError: true }, ...options }
    const cancelSource = CancelToken.source();
    const cancelToken = cancelSource.token
    const { showError } = options

    return requester(Object.assign({
      method,
      url,
      headers: getHeader(),
      cancelToken
    }, method == 'GET' ? { params: params } : { data: params }))
  },
  request: (url, callback = () => { }, params = {}, method = 'GET', options = {}) => {

    options = { ...{ showError: method != 'GET', showSuccess: false }, ...options }

    const cancelSource = CancelToken.source();
    const cancelToken = cancelSource.token
    const { showError, baseURL, forceCallbackError } = options

    requester(Object.assign({
      method,
      url,
      baseURL,
      headers: getHeader(options.bearerToken),
      cancelToken
    }, method == 'GET' ? { params: params } : { data: params })).then((response) => {
      callback(response.data, true);
      if (options.showSuccess) {
        toast.success(response.data.message ? response.data.message : (method == 'DELETE' ? 'Data removed !' : 'Data saved !'))
      }
    }).catch((error) => {
      if (forceCallbackError) {
        callback(error, false)
        return
      }

      if (!error.response) {
        return;
      }

      if (showError) {
        showCommonError(error);
      }

      callback(error, false)
    });

    return cancelSource
  },
  showCommonError: showCommonError,
  form: form,
  url: {
    scheme,
    baseDomain,
    base: baseUrl,
    baseApi: baseApiUrl,
    ws: wsScheme + baseDomain,
    admin: baseAdminUrl
  },
  getToken,
  applyLanguages: (fields, data) => {
    for (var k in data) {
      if (fields.indexOf(k) > -1 && typeof data[k] == 'string') {
        data[k] = {
          th: data[k],
          en: data[k]
        }
      }
    }

    return data
  }
}

function modifier(formData, params) {
  //append something

  return formData;
}

export default api;
