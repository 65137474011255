import React from "react";
import {Row, Col, Card, Table, Button, Modal, Spinner} from 'react-bootstrap';
import Colors from '../utils/styles/Colors'

export function ConfirmModal({title, message, cancelText = 'ยกเลิก',
  confirmText = 'ลบ', show = false, onCancel, onConfirm, size = 'sm'}) {
  return <Modal size={size}
  backdrop={'static'}
  keyboard={false}
  scrollable centered show={show} onHide={()=>onCancel()}>
      <Modal.Body>
        <h5>{title}</h5>
        <div dangerouslySetInnerHTML={{__html: message}} />
      </Modal.Body>
      <Modal.Footer>
        <div className="py-2 px-3">
          <a style={{color: '#000'}} className="mr-4 cursor-pointer" onClick={onCancel}>{cancelText}</a>
          <Button onClick={onConfirm} size="sm" variant="secondary">{confirmText}</Button>
        </div>
      </Modal.Footer>
    </Modal>
}

export function FullscreenLoading({show, text="Loading..."}) {
  return <Modal size="sm" centered show={show} onHide={() => {}}>
    <div className="row-center py-3"><Spinner className="mr-3" animation="border" /> {text}</div>
  </Modal>
}
